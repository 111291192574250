.Main {
  overflow: hidden;
}

.Main-images {
  display: flex;
  justify-content: center;
}

.titlemodel {
  padding-bottom: calc(120 / 1920 * 100vw);
  background: url("/public/images/title-underline.png") no-repeat bottom center;
  background-size: auto calc(200 / 1920 * 100vw);
  text-align: center;
}

.scroll-to-top {
  display: flex;
  position: fixed;
  right: 48px;
  bottom: 48px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.snowflake {
  position: fixed;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: fall 10s linear infinite;
}

@keyframes fall {
  from {
    top: -10%;
    transform: translateX(0) rotate(0deg);
  }
  to {
    top: 110%;
    transform: translateX(-200px) rotate(360deg);
  }
}

@keyframes fallReverse {
  0% {
    top: -10%;
    transform: translateX(0) rotate(0deg);
  }
  100% {
    top: 110%;
    transform: translateX(200px) rotate(-360deg);
  }
}

@media (width < 1100px) {
  .Main-images {
    display: flex;
    justify-content: center;
  }

  .Main-images img {
    width: 200px;
  }

  .titlemodel {
    padding-bottom: calc(128 / 850 * 100vw);
    background-size: auto calc(200 / 850 * 100vw);
  }
}
