.Home {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/public/images/bg.png");
}

.Home-image {
  width: calc(350 / 1920 * 100vw);
  cursor: pointer;
}

.Home-image:hover {
  transform: scale(107%);
}

.svg-back {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.copy-btn {
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Home-cats {
  width: 768px;
}

.Home-container {
  position: relative;
  top: 64px;
  display: flex;
  max-width: 340px;
  flex-direction: column;
  align-items: center;
  z-index: 0;
}

.outline {
  -webkit-text-stroke-color: #c48252;
  -webkit-text-stroke-width: 2px;
}

.Home-divider {
  border: 1px white solid;
  margin: 2rem 0;
}

.Home-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.Home-btn {
  color: black;
  background: #e60d00;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 2rem;
  font-size: 1.25rem;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
}

.Home-iconbtns {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.Home-iconbtns img {
  border: 2px solid #c48252;
  border-radius: 25%;
  padding: 5px;
  animation: tada 2s infinite;
}

.Home-iconbtns img:hover {
  animation: none;
}

.Home-iconbtns a:hover {
  transform: scale(110%);
}

#second-heading {
  text-shadow: 0.04419417em 0.04419417em 0.075em rgba(0, 0, 0, 1);
  animation: slideInFromLeft 0.5s ease-out forwards;
  text-align: center;
}

.Home-iconbtns a:not(:last-child) {
  margin-right: 1.5rem;
  max-width: 50%;
}

.showLeft {
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.showRight {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.showBottom {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

@media (max-width: 432px) and (min-width: 372px) {
  #second-heading {
    font-size: 4rem !important;
  }
}

@media (max-width: 371px) {
  #second-heading {
    font-size: 2.8rem !important;
  }
}

@media (width < 1100px) {
  .Home {
    display: block;
    padding-top: 2.5rem;
  }
  .Home-container {
    max-width: 100%;
    min-width: 0;
  }

  .Home-cats {
    width: 100%;
  }

  .Home-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .Home-container div:first-child h1 {
    font-size: 72px !important;
    white-space: pre;
  }

  .Home-container div:nth-child(2) h1 {
    font-size: 32px !important;
    font-weight: 300;
  }

  .mobile-column {
    z-index: 1;
  }

  .Home-image {
    width: calc(350 / 768 * 100vw) !important;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
