.About {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;
}

.About-image {
  position: relative;
  width: 400px;
  height: auto;
  border-radius: 4%;
  animation: bump 3s infinite;
}

.About-span {
  font-size: 2rem;
  font-weight: 400;
  z-index: 1;
}

.About-button-box {
  flex-direction: row !important;
  gap: 15px;
  width: auto !important;
  display: flex;
}

.About-button-box a:first-child {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2em;
  padding: 12px;
  color: #fff;
  background-color: #c48252;
  border-radius: 8px;
  box-shadow: 0px 1px 5.25px 0px rgba(0, 0, 0, 0.3);
}

.About-button-box a:nth-child(2) {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2em;
  padding: 12px;
  color: #000;
  background-color: #ebebeb;
  border-radius: 8px;
  box-shadow: 0px 1px 5.25px 0px rgba(0, 0, 0, 0.3);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3.5rem;
  justify-items: center;
}

.grid-container div {
  display: flex;
  align-items: center;
}

.opacity {
  opacity: 0;
}

.horse {
  width: 100px;
  height: 100px;
  background-image: url("https://media.tenor.com/kttV6V_t16QAAAAi/horse-joypixels.gif");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  animation: run 4s linear forwards;
}

.racetrack {
  width: 100%;
  border-radius: 6px;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.About-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 150px 0;
  width: 100%;
}

.align-center {
  align-items: center;
}

.align-flex-start {
  align-items: flex-start;
}

.About-container div {
  display: flex;
  flex-direction: column;
}
.About-title {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  color: #c48252;
  text-align: center;
  width: 100%;
  font-style: italic;
  white-space: pre;
}

/* .gradient-text {
  padding-right: 12px;
  background-image: linear-gradient(to right, #c48252, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.About-benefit-container {
  width: 100%;
  border-radius: 16px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
  padding: 20px 32px;
  margin: 20px 0;
}
.About-divider {
  border: 1px #99016a solid;
  margin: 2rem 0;
  width: 100%;
}

.About-content {
  font-size: 24px;
  font-weight: 400;
  line-height: 2.3rem;
  min-height: 200px;
  margin-bottom: 20px !important;
}

.About-small-container {
  width: 200px;
  border-radius: 8px;
  background-color: white;
  padding: 1rem;
}

.container-a {
  position: relative;
}

.container-b {
  position: absolute;
  left: calc(-280 / 1920 * 100vw);
  top: calc(950 / 1920 * 100vw);
}

.container-c {
  position: absolute;
  right: calc(-280 / 1920 * 100vw);
  top: calc(950 / 1920 * 100vw);
}

.auto-resize {
  position: absolute;
  width: calc(1380 / 1920 * 100vw);
}

.About-small-container span {
  font-size: 20px;
  color: black;
  line-height: 1.5rem;
}

.carousel-item {
  display: flex !important;
  justify-content: center;
  gap: 40px;
  margin: 24px 0;
}

.url-img {
  background-image: url("/public/images/frame.png");
  background-size: cover;
}

.slick-next {
  right: 36px;
}

.slick-prev {
  left: 36px;
}

.carousel-title {
  font-size: 40px;
  font-weight: 600;
}

.carousel-content {
  margin: 24px 0;
  font-size: 16px;
  line-height: 1.6em;
  color: #666666;
}

.carousel-link {
  margin: 12px 0;
  width: fit-content;
}

.carousel-link span {
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  border: 2px solid #000;
  padding: 8px;
}

.frame-wide {
  position: absolute;
  width: 100% !important;
  height: 100%;
}

.frame-wide img {
  width: 300px;
}

.frame-wide img:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.frame-wide img:nth-child(2) {
  position: absolute;
  bottom: 0;
  left: 0;
}

.frame-wide img:nth-child(3) {
  position: absolute;
  top: 0;
  right: 0;
}

.frame-wide img:nth-child(4) {
  position: absolute;
  bottom: 0;
  right: 0;
}

.showLeft {
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.showRight {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.showBottom {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

@media (width < 900px) {
  .About-button-box {
    justify-content: center;
  }
  .auto-width {
    width: auto !important;
    align-items: center;
  }
  .url-img {
    background-image: none;
    flex-direction: column;
  }
  .About {
    display: block;
    padding: 1rem;
  }

  .About-container {
    flex-direction: column;
    padding: 0 8px;
  }

  .About-image {
    width: 300px;
  }

  .About-content {
    text-align: center;
  }

  .auto-resize {
    width: calc(640 / 756 * 100vw);
  }

  .opacity {
    width: 100%;
  }

  .About-title {
    font-size: 32px;
  }

  .align-flex-start {
    align-items: center;
  }

  .auto-col {
    flex-direction: column;
    align-items: center;
  }

  .carousel-item {
    display: grid !important;
    padding: 0;
    justify-items: center;
  }

  .carousel-content {
    text-align: center;
  }

  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }

  .frame-wide {
    display: none !important;
  }

  .About-mobile {
    flex-direction: column;
    align-items: center;
  }
}

@keyframes run {
  0% {
    right: -100px;
  }
  100% {
    right: 100vw;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideLeftFromRight {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(450px);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes bump {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(105%);
  }
  100% {
    transform: scale(100%);
  }
}
