.Contact {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact-container {
  width: 540px;
}
.Contact-title {
  font-size: 50px;
  font-weight: bold;
}

.grid-container {
  display: grid;
  width: 100%;
  margin: 2rem 0;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 10px;
  margin-bottom: 8px;
  margin-right: 8px;
}

input,
textarea {
  padding: 14px;
  background-color: #f2f2f2;
  border-radius: 8px;
  border: 0;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  box-sizing: border-box;
}

textarea {
  height: 100px;
  resize: vertical;
}

.Contact-button {
  padding: 15px;
  background-color: #222222;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0;
}

.Contact-button:hover {
  background-color: #c48252;
}

.Contact-footer {
  width: 100%;
  padding: 1rem;
  background-color: #000;
  text-align: center;
  margin-top: 20px;
  font-size: 0.9em;
  color: #fff;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.footer-category {
  margin: 0 20px;
}

.footer-category h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-category ul {
  list-style-type: none;
  padding: 0;
}

.footer-category ul li {
  margin-bottom: 10px;
}

.footer-category ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-category ul li a:hover {
  text-decoration: underline;
}

.Contact-image {
  width: calc(1920 / 1920 * 100vw);
}

@media (width < 900px) {
  .mobile-col {
    flex-direction: column;
  }

  .Contact-image {
    width: calc(1920 / 768 * 100vw);
  }
}
