.HowToBuy {
  margin: 0;
}

.HowToBuy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  gap: 2.5rem;
}

.HowToBuy-title {
  color: #c48252;
  font-size: 4.2rem;
  font-weight: 500;
  text-align: center;
}

.HowToBuy-small-container {
  display: flex;
  opacity: 0;
  width: 60%;
  border-radius: 0 16px 0 16px;
  border: 2px #c48252 solid;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px;
  gap: 24px;
}

.HowToBuy-subtitle {
  color: #c48252;
  font-size: 32px;
  font-weight: 600;
  margin: 8px;
}

.HowToBuy-text {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  margin: 8px;
}

.HowToBuy-img {
  width: 80px;
}

.HowToBuy-jup {
  display: flex;
  justify-content: center;
  margin: 4rem 0;
  width: 60%;
  background-color: gray;
  border-radius: 10px;
}

.w-full {
  width: 100%;
}

@media (max-width: 900px) {
  .HowToBuy-small-container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }

  .HowToBuy-subtitle {
    text-align: center;
  }

  .HowToBuy-text {
    text-align: center;
  }
  .HowToBuy-jup {
    width: 100%;
  }
}
